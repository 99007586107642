<!-- =========================================================================================
  File Name: DataListThumbView.vue
  Description: Data List - Thumb View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="data-list-thumb-view" class="data-list-container">
  <vs-popup class="holamundo"  title="اضافة تخفيض للمنتج 123" :active.sync="popupActive">
      <div class="popup-style w-full h-full flex flex-col justify-between ">
        <div class="mb-10">
          <label for="status" class="w-full mb-1">سعر المنتج بعر التخفيض </label>
         <vs-input class="w-full" v-model="discount" />
        </div>
        <div class="w-full text-center">
          <vs-button class="rounded mx-3" color="danger" type="border" @click="togglePopup">إلغاء</vs-button>
          <vs-button class="rounded mx-3" color="primary" @click="giveDiscount()">اضافة</vs-button>
        </div>
      </div>
    </vs-popup>
    <vs-table
      ref="table"
      multiple
      pagination
      search
      v-model="selected"
      :max-items="itemsPerPage"
      :data="users"
    >
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
        <!-- ITEMS PER PAGE -->
        <vs-button
          class="
            p-3
            mb-4
            mr-4
            rounded-lg
            cursor-pointer
            flex
            items-center
            justify-center
          "
          :disabled="!selected.length"
          @click="togglePopup"
        >
          <span class="ml-2">اعطاء تخفيض</span>
        </vs-button>
        <div class="flex">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div
              class="
                p-4
                border border-solid
                d-theme-border-grey-light
                rounded-full
                d-theme-dark-bg
                cursor-pointer
                flex
                items-center
                justify-between
                font-medium
              "
            >
              <span class="mr-2"
                >{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} -
                {{
                  users.length - currentPage * itemsPerPage > 0
                    ? currentPage * itemsPerPage
                    : users.length
                }}
                of {{ queriedItems }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item @click="itemsPerPage = 4">
                <span>4</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage = 10">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage = 15">
                <span>15</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage = 20">
                <span>20</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
      </div>

      <template slot="thead">
        <vs-th>الصورة</vs-th>
        <vs-th sort-key="name">الإسم</vs-th>
        <vs-th sort-key="phone">رقم الهاتف</vs-th>
        <vs-th sort-key="phone">سعر المنتج بعر التخفيض</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td
              class="table-image-container"
            >
              <img :src="tr.user.image ? tr.user.image : default_img" class="table-image" />
            </vs-td>
            <vs-td>
              <p class="user-name font-medium truncate">{{ tr.user.name }}</p>
            </vs-td>

            <vs-td>
              <p class="userphone">{{ tr.user.phone }}</p>
            </vs-td>
            <vs-td>
              <p class="userphone">{{ tr.discount ? tr.discount : 0 }} د.ل</p>
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
export default {
  data() {
    return {
      search: "",
      selected: [],
      current_Page: 1,
      itemsPerPage: 5,
      lastPage: 0,
      totalItems: 0,
      discount:0,
      popupActive: false,
    };
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
    queriedItems() {
      return this.$refs.table
        ? this.$refs.table.queriedResults.length
        : this.users.length;
    },
    users(){
      return this.$store.state.wishlist.users
    }
  },

  methods: {
    ...mapActions('wishlist', [
      'fetchWishlistUsers',
      'giveWishlistDiscount',
    ]),
    togglePopup() {
        this.popupActive=!this.popupActive
    },
    giveDiscount(){
      let id = this.$route.params.id
      let users = this.selected.map(item => item.user_id)
      var bodyFormData = new FormData();
      bodyFormData.set('discount_value',this.discount)
      for(var user in users){
        bodyFormData.set(`users[${user}]`,users)
      }
      
      this.giveWishlistDiscount({id,bodyFormData}).then((response)=>{
        this.successDialog(response.data.message)
        this.popupActive = false
      })
      .catch((error)=>{
        this.errorDialog(error)
      })
    }
  },
  mounted() {},
  created() {
    this.fetchWishlistUsers(this.$route.params.id)
  },
};
</script>

<style lang="scss">
#data-list-thumb-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);

        td {
          padding: 10px;

          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }

          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }

          &.table-image-container {
            height: 110px;

            // background: #fff;

            span {
              display: flex;
              justify-content: flex-start;
            }

            .table-image {
              height: 68px;
            }
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
